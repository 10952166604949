.button {
  background-color: #D42E29;
  outline: none;
  border: none;
  color: white;
  text-align: center;
  padding: 1rem 5rem;

  width: 100%;

  align-items: center;

  margin-top: 4rem;
  font-size: 3rem;
  border-radius: 0rem;
  position: center;

  transition: background-color 500ms;

  &:hover {
    background-color: #FA6D2A;
  }
}
