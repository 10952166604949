.loading-view {
  font-style: normal;
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;

  height: 100%;
  width: 100%;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  z-index: 1;

  > .bg-working {
    background-image: url(../../assets/bg-posto.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    height: 100%;
    width: 100%;

    z-index: -1;

    position: absolute;

  }
}
