
  body::-webkit-scrollbar {
    width: 10px;               /* width of the entire scrollbar */
  }

  body::-webkit-scrollbar-track {
    background: transparent;        /* color of the tracking area */
  }

  body::-webkit-scrollbar-thumb {
    background-color: #D42E29;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 2px solid white;  /* creates padding around scroll thumb */
  }

.home-page {
  height: 100%;

  .content {
    height: inherit;

    background-image: url(../../assets/fundo-home.png);
    background-size: cover;
    background-position: center;

    > .try-it-poshcash {
      height: inherit;
      padding-left: 114px;
      padding-right: 72px;

      position: relative;
      
      background-image: url(../../assets/homemsorrindo.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom center;

      display: flex;
      justify-content: space-between;
      align-items: center;

      > h5 {
        color: #ffffff;
        font-size: 56px;
        font-weight: 400;
        width: 55rem;
        > span {
          font-weight: 700;
        }

        > a{
          font-size: 24px;
          text-align: left;
          color: #ffffff;
          font-weight: 500;
        }
      }

      > .card {
        position: relative;
        background-color: white;
        border-radius: 1.6rem;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 48px;
        width: 576px;
        height: 459px;
        display: flex;
        flex-direction: column;

        > span {
          color: #544840;
          font-size: 32px;
          font-weight: 600;
        }

        > input {
          margin-top: 6rem;
          margin-bottom: 2rem;
          width: 100%;
          font-size: 2rem;
          padding: 1rem 0;

          border: none;
          border-bottom: 1px solid #414141;

          &:focus {
            outline: none;
          }
          &::placeholder {
            color: #4d4d4d;
          }
        }
      }

      @media (max-width: 1024px) {
        flex-direction: column;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;

        > h5 {
          width: 100%;
          text-align: center;
          margin: 2rem;
          font-size: 4.8rem;
          > a{
            font-size: 16px;
          }
        }

        > .card {
          margin: 5rem;
        }

        .arrow {
          bottom: 50%;
        }
      }
      @media (max-width: 960px) {
        > h5 {
          font-size: 3.2rem;
        }
      }
      @media (max-width: 768px) {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        
        padding: 0;
        > h5 {
          font-size: 2.4rem;
          > a{
            font-size: 2rem;
          }
        }

        > .card {
          width: 100%;
          margin: 0;
          border-radius: 0;
          border-top-left-radius: 3.4rem;
          border-top-right-radius: 3.4rem;
          > input {
            margin-top: 2rem;
          }
          > span {
            font-size: 2.4rem;
          }
          > button {
            margin-top: 1rem;

            background-size: 2.5rem;
            font-size: 2.4rem;
          }
        }
      }
      
      @media (max-width: 599px) {
        h5{
          font-size: 24px;
          text-align: left;
          padding-left: 39px;
          padding-top: 45px;
          padding-right: 77px; 

          > a{
            font-size: 16px;
          }
        }

        flex-direction: column;

        background-size: 95%;
        background-repeat: no-repeat;
        background-position: bottom center;

        > .card {
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
          padding-top: 20px;
          height: auto;
          > span {
            font-size: 20px;
          }
          > a .button {
            margin-top: 20px;
            margin-bottom: 40px;
            font-size: 1.8rem;
          }
        }
      }

      @media (max-width: 375px) {
        h5{
          font-size: 20px;
          text-align: left;
          padding-left: 39px;
          padding-top: 45px;
          padding-right: 77px;

          > a {
            font-size: 14px;
          }
        }

        flex-direction: column;

        background-size: 98%;
        background-repeat: no-repeat;
        background-position: bottom center;

        > .card {
          height: auto;

          > input {
            font-size: 18px;
          }
          > span {
            font-size: 18px;
          }
          > a .button {
            margin-top: 15px;
            margin-bottom: 20px;
            font-size: 18px;
          }
        }
      }

      @media (max-width: 360px) {
        h5{
          font-size: 16px;
          text-align: left;
          padding-left: 39px;
          padding-top: 45px;
          padding-right: 77px;

          > a{
            font-size: 12px;
          }
          > img{
            padding-top: 5px;
            height: 16px;
            width: 16px;
          }
        }

        flex-direction: column;

        background-size: 93%;
        background-repeat: no-repeat;
        background-position: bottom center;

        > .card {
          padding-top: 20px;
          height: auto;

          > input {
            font-size: 14px;
          }
          > span {
            font-size: 14px;
          }
          > a .button {
            margin-top: 5px;
            margin-bottom: 30px;
            font-size: 14px;
          }
        }
      }

      @media (max-width: 320px) {
        h5{
          font-size: 16px;
          text-align: left;
          padding-left: 39px;
          padding-top: 25px;
          padding-right: 77px;

          > a{
            font-size: 12px;
          }
        }

        flex-direction: column;

        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom center;

        > .card {
          padding-top: 20px;
          height: auto;

          > input {
            font-size: 14px;
          }
          > span {
            font-size: 14px;
          }
          > a .button {
            margin-top: 12px;
            margin-bottom: 30px;
            font-size: 14px;
          }
        }
      }

      @media (max-width: 280px) {
        h5{
          font-size: 16px;
          text-align: left;
          padding-left: 39px;
          padding-top: 25px;
          padding-right: 77px;

          > a{
            font-size: 12px;
          }
        }

        flex-direction: column;

        background-size: 120%;
        background-repeat: no-repeat;
        background-position: bottom center;

        > .card {
          padding-top: 20px;
          height: auto;

          > input {
            font-size: 14px;
          }
          > span {
            font-size: 14px;
          }
          > a .button {
            margin-top: 12px;
            margin-bottom: 30px;
            font-size: 14px;
          }
        }
      }
    }
    > .money-back-to-you {
      background-color: #FCF9F9;
      height: inherit;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;

      img {
        width: 100%;
      }

      p {
        color: #544840;
        font-size: 40px;
        font-weight: 400;
        padding-right: 150px;
        line-height: 60px;
        span {
          color: #D42E29;
          display: inline-block;
          font-size: 56px;
          font-weight: 700;
          line-height: 84px;
        }
      }
      @media (max-width: 1400px) {
        p {
          margin: 7rem;

          font-size: 4rem;
          span {
            font-size: 6rem;
          }
        }
      }
      @media (max-width: 1024px) {
        justify-items: center;
        img {
          height: 100%;
          width: unset;
        }
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
      }
      @media (max-width: 600px) {
        p {
          margin: 2rem;
          font-size: 2rem;

          span {
            font-size: 3.5rem;
            display: block;
          }
        }
      }
    }
    > .buy-and-make-money {
      height: inherit;
      background-image: url(../../assets/pessoas-celulares.png);
      background-position: center;
      background-size: cover;
      position: relative;

      h2{
        color: #FFFFFF;
        font-size: 56px;
        font-weight: 400;
        padding-left: 116px;
        padding-right: 1228px;
        padding-top: 420px;

        span{
          font-weight: 700;
        }
      }

      // p {
      //   margin: 0;
      //   padding: 0;

      //   position: absolute;
      //   top: 10rem;
      //   left: 7rem;
      //   width: 50rem;

      //   text-shadow: 0px 3px 6px #00000047;
      //   color: #ffffff;
      //   font-weight: 700;
      //   font-size: 5rem;
      // }
      @media (max-width: 768px) {
        p {
          position: initial;
          width: 100%;
          padding: 3rem;
          text-align: center;
        }
      }
    }
    > .buy-and-win {
      height: inherit;
      background-color: #FFFFFF;;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;

      > :first-child {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 707px;
          height: 707px;
        }
      }
      > :last-child {
        height: inherit;
        margin-left: 5rem;
        margin-top: 212px;
        padding-right: 124px;
        // margin-top: 212px;
        > h4 {
          // margin: 5rem 0;
          color: #D42E29;
          font-weight: 700;
          font-size: 56px;
          padding-right: 280px;
          line-height: 84px;
        }

        h6 {
          color: #544840;
          font-size: 40px;
          font-weight: 400;
          line-height: 60px;
          padding-top: 24px;
        }

        p {
          margin: 0;
          padding: 0;
          color: #5f5f5f;
          font-size: 3.5rem;
          font-weight: 300;
          span {
            font-weight: 400;
          }
        }
      }

      @media (min-width: 1501px) {
        > :last-child {
          img {
            height: 100%;
            width: unset;
          }
        }
      }
      @media (max-width: 1500px) {
        > :first-child {
          h4 {
            font-size: 6rem;
          }
          h6 {
            margin: 5rem 0;
          }
        }
      }
      @media (max-width: 1080px) {
        > :first-child {
          h4 {
            margin: 3rem 0;
          }
          h6 {
            margin: 3rem 0;
            font-size: 3.5rem;
          }
          p {
            font-size: 3rem;
          }
        }
        > :last-child {
          img {
            width: 70%;
          }
        }
      }
      @media (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        height: min-content;
        > :first-child {
          h4 {
            font-size: 5rem;
          }
        }
      }
      @media (max-width: 600px) {
        > :first-child {
          padding: 1rem;
          margin: 0;
          h4 {
            font-size: 3.2rem;
            text-align: center;
            margin: 1rem 0;
          }
          h6 {
            font-size: 2.2rem;
            margin: 1rem 0;
          }
          p {
            font-size: 2rem;
          }
        }
        > :last-child {
          padding: 3rem;
        }
      }
    }

    > .our-partners {
      height: inherit;
      background-color: #FCF9F9;

      > :first-child {
        align-items: center;

        h2{
          text-align: center;
          color: #D42E29;
          font-size: 56px;
          padding-top: 110px;
          padding-bottom: 16px;
        }

        h6{
          text-align: center;
          color: #544840;
          font-size: 40px;
          font-weight: 100 ;
        }
      }
    }
    
    > .start-earning {
      height: 100%;
      background-image: url(../../assets/bg-posto.png);
      background-position: center;
      background-size: cover;
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      align-items: center;

      > :first-child {
        margin-left: 116px;
        margin-right: 22rem;
        h2 {
          color: #FFFFFF;
          font-weight: 300;
          font-size: 96px;
          span {
            font-weight: 700;
          }
        }
        p {
          color: #FFFFFF;
          font-weight: 400;
          font-size: 40px;
        }

        div {
          position: relative;
          max-width: 35rem;
          margin-top: 15rem;

          font-size: 7rem;

          :nth-child(1) {
            color: white;
            font-weight: 300;
            font-family: Museo;
          }
          :nth-child(2) {
            color: white;
            font-family: Museo;
            font-weight: 700;
          }
          :nth-child(3) {
            color: white;
            font-family: Museo;
            font-weight: 300;
            right: 0;
            position: absolute;
          }
        }
      }

      > :last-child {
        display: flex;
        align-items: center;
        padding-right: 65px;

        img {
          padding-top: 14px;
          width: 100%;
        }
      }

      @media (max-width: 1300px) {
        > :first-child {
          h2 {
            font-size: 7.5rem;
          }
        }
      }
      @media (max-width: 1000px) {
        > :first-child {
          h2 {
            font-size: 6.5rem;
          }
          > button {
            font-size: 2.5rem;
          }
          div {
            font-size: 6rem;
          }
        }
      }
      @media (max-width: 880px) {
        :first-child {
          > button {
            font-size: 2rem;
          }
        }
      }
      @media (max-width: 800px) {
        grid-template-columns: initial;
        height: auto;
        > :first-child {
          margin: 0;
          padding: 5rem;
          margin-bottom: 5rem;

          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          > button {
            margin: 2rem 0;
            width: 100%;
          }
          > div {
            margin: 0;
          }
        }
      }
      @media (max-width: 600px) {
        :first-child {
          h2 {
            font-size: 4rem;
            text-align: center;
          }
          .button {
            background-size: 2rem;
          }
          div {
            font-size: 3rem;
          }
        }
      }
    }
  }
}
