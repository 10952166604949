@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

html {
  font-size: 10px;
}

* {
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: Museo;
  src: url(../../assets/Museo700-Regular.woff);
  src: url(../../assets/Museo300-Regular.woff) format("woff");
}
