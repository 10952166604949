.form-signup-page {
  height: inherit;

  .content {
    height: inherit;

    display: grid;
    grid-template-columns: 35% 65%;

    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .bg-image {
      height: 100%;

      background-image: url(../../assets/fundo-home.png);
      background-size: cover;
      background-position: center;
    }

    .bg-color {
      height: 100%;

      background: transparent linear-gradient(180deg, #D23030 0%, #FA6D2A 100%);

      @media (max-width: 800px) {
        display: none;
      }
    }

    .form-left-container {
      height: inherit;

      display: flex;
      justify-content: center;

      padding: 4rem;

      color: white;

      background-image: url(../../assets/homemsorrindo.png);
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: 95%;

      h4 {
        text-align: left;
        margin-top: 4rem;
        font-weight: lighter;
        font-size: 4rem;
      }

      @media (max-width: 1000px) {
        padding: 2rem;
      }
    }

    .form-right-container {
      padding: 10rem;

      h3 {
        margin-top: -10px;
        padding-top: -10px;
        width: 55rem;
        color: #414141;
        font-size: 3.5rem;

        @media (max-width: 800px) {
          width: 100%;
        }
      }

      @media (max-width: 1000px) {
        padding: 5rem;
      }
      @media (max-width: 800px) {
        padding: 2rem;
      }
    }

    .form {
      & > div {
        margin-top: 2rem;
      }
      .agreement {
        & > input {
          margin: 1rem;
          min-width: 1.6rem;
          min-height: 1.6rem;

          @media (max-width: 800px) {
            margin: 0.5rem;
            min-width: 1.8rem;
            min-height: 1.8rem;
          }
        }

        @media (max-width: 800px) {
          flex-direction: column;
        }
      }
    }
  }
}
