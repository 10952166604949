.error-page {
  height: inherit;
  background-color: #FCF9F9;
  justify-items: center;
  display: flex;
  align-items: center; 
  justify-content: center;
  padding-top: 74px;
  padding-right: 0px;
  padding-left: 161px;

  > .first-column {

    h2 {
      padding-right: 250px;
      color: #544840;
      font-size: 40px;
      font-weight: 400;
      line-height: 60px;
      span {
        color: #D42E29;
        font-size: 56px;
        font-weight: 700;
        line-height: 84px;
      }
      
      button{
        background: #FCF9F9;
        color: #E74C20;
        border: none;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        text-decoration: underline;
      }
    }
  }

  > .image{
    img {
      width: 861px;
      height: 856px;
    }
  }

  @media (max-width: 1024px) {
    padding-top: 100px;
    padding-left: 0px;
    flex-direction: column;
    display: table;

    >.first-column{
      
      display:table-footer-group;
      h2{
        padding-bottom: 80px;
        padding-right: 25px;
        padding-left: 25px;   
        text-align: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; 
        > span{
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
        }
        > button{
          padding-top: 15px;
          font-size: 16px;
          font-weight: 300;
          line-height: 36px;
        }
      }
    }
    > .image{
      img{
        padding-left: 100px;
        padding-right: -50px;
        display:table-header-group;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 414px) {
    padding-top: 100px;
    padding-left: 0px;
    flex-direction: column;
    display: table;

    >.first-column{
      
      display:table-footer-group;
      h2{
        padding-bottom: 80px;
        padding-right: 25px;
        padding-left: 25px;   
        text-align: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; 
        > span{
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
        }
        > button{
          padding-top: 15px;
          font-size: 16px;
          font-weight: 300;
          line-height: 36px;
        }
      }
    }
    > .image{
      img{
        padding-left: 100px;
        padding-right: -50px;
        display:table-header-group;
        width: 100%;
        height: 100%;
      }
    }
  }
}