.input-style {
  input {
    width: 100%;
    font-size: 2rem;
    padding: 1rem 0;

    border: none;
    border-bottom: 1px solid #414141;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #a8a8a8;
      // opacity: 37%;
    }
  }

  label {
    font-size: 1.2rem;
    color: #555;
  }

  .error-input {
    color: red;
    font-size: 1.2rem;

    display: block;

    margin-top: 0.5rem;
  }
}
