.success-page {
  height: inherit;
  background-color: #FCF9F9;
  justify-items: center;
  display: flex;
  align-items: center; 
  justify-content: center;
  padding-top: 70px;
  padding-right: 116px;
  padding-left: 116px;

  > .first-column {

    h2 {
      padding-right: 110px;
      color: #544840;
      font-size: 40px;
      font-weight: 400;
      line-height: 60px;
      span {
        color: #D42E29;
        font-size: 56px;
        font-weight: 700;
        line-height: 84px;
      }
      
      p{
        padding-top: 36px;
        color: #544840;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
      }
    }    
    a {
      text-decoration: none;
      border: none;
      img{
        padding-top: 20px;
        width: 30%;
        height: 30%;
      }
    } 
  }

  >.image{
    img{
      width: 707px;
      height: 707px;
    }
  }

  @media (max-width: 1024px) {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    align-items: center;
    flex-direction: column;
    display: table;

    > .first-column{
      display:table-footer-group;
      h2{
        padding-bottom: 40px;
        padding-right: 25px;
        padding-left: 25px;
        text-align: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;      
        > span{
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
        }
        > p{
          padding-top: 15px;
          font-size: 16px;
          font-weight: 300;
          line-height: 36px;
        }
      }
      

    }
    >.image{
      img{
        display:table-row-group;
        width: 100%;
        height: 100%;

      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    align-items: center;
    flex-direction: column;
    display: table;

    > .first-column{
      display:table-footer-group;
      h2{
        padding-bottom: 40px;
        padding-right: 25px;
        padding-left: 25px;
        text-align: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;      
        > span{
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
        }
        > p{
          padding-top: 15px;
          font-size: 16px;
          font-weight: 300;
          line-height: 36px;
        }
      }
      

    }
    >.image{
      img{
        display:table-row-group;
        width: 100%;
        height: 100%;

      }
    }
  }

  @media (max-width: 414px) {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    align-items: center;
    flex-direction: column;
    display: table;

    > .first-column{
      display:table-footer-group;
      h2{
        padding-bottom: 40px;
        padding-right: 25px;
        padding-left: 25px;
        text-align: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;      
        > span{
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
        }
        > p{
          padding-top: 15px;
          font-size: 16px;
          font-weight: 300;
          line-height: 36px;
        }
      }
      

    }
    >.image{
      img{
        display:table-row-group;
        width: 100%;
        height: 100%;

      }
    }
  }

  @media (max-width: 411px) {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    align-items: center;
    flex-direction: column;
    display: table;

    > .first-column{
      display:table-footer-group;
      h2{
        padding-bottom: 80px;
        padding-right: 25px;
        padding-left: 25px;
        text-align: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;      
        > span{
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
        }
        > p{
          padding-top: 15px;
          font-size: 16px;
          font-weight: 300;
          line-height: 36px;
        }
      }
      

    }
    >.image{
      img{
        display:table-row-group;
        width: 100%;
        height: 100%;

      }
    }
  }


  @media (max-width: 375px) {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    align-items: center;
    flex-direction: column;
    display: table;

    > .first-column{
      display:table-footer-group;
      h2{
        padding-bottom: 150px;
        padding-right: 25px;
        padding-left: 25px;
        text-align: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;      
        > span{
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
        }
        > p{
          padding-top: 15px;
          font-size: 16px;
          font-weight: 300;
          line-height: 36px;
        }
      }
      

    }
    >.image{
      img{
        display:table-row-group;
        width: 100%;
        height: 100%;

      }
    }
  }

  @media (max-width: 360px) {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    align-items: center;
    flex-direction: column;
    display: table;

    > .first-column{
      display:table-footer-group;
      h2{
        padding-bottom: 80px;
        padding-right: 25px;
        padding-left: 25px;
        text-align: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;      
        > span{
          font-size: 20px;
          font-weight: 700;
          line-height: 36px;
        }
        > p{
          padding-top: 15px;
          font-size: 14px;
          font-weight: 300;
          line-height: 36px;
        }
      }
      

    }
    >.image{
      img{
        display:table-row-group;
        width: 100%;
        height: 100%;

      }
    }
  }
  

  @media (max-width: 320px) {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    align-items: center;
    flex-direction: column;
    display: table;

    > .first-column{
      display:table-footer-group;
      h2{
        padding-bottom: 5px;
        padding-right: 25px;
        padding-left: 25px;
        padding-top: 20px;
        text-align: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;      
        > span{
          font-size: 18px;
          font-weight: 700;
          line-height: 36px;
        }
        > p{
          padding-top: 0px;
          font-size: 13px;
          font-weight: 300;
          line-height: 36px;
        }
      }
      

    }
    >.image{
      img{
        display:table-row-group;
        width: 100%;
        height: 100%;

      }
    }
  }
}
